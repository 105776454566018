/**
 * @file
 * HTML setup
 */

* {
	box-sizing: border-box;
   	outline: 0;
}

html {
	height: 100%;
}
 
body {
	background: url(/images/roastco-bg.jpg) no-repeat;
	background-position: center top;
	background-size: cover;
	color: #fff;
	font-family: 'niveau-grotesk', sans-serif;
	height: 100%;
	margin: 0;
	padding: 0;
	width: 100%;
}

a {
	//color: $orange;
	//text-decoration: none;

	&:hover {
	//	color: $grey;
	}
}

// Clear iOS styles on specific form elements
input[type="text"],
input[type="email"],
input[type="submit"] {
	-webkit-appearance: none;
	border-radius: 0;
}

input,
textarea {
	//font-family: 'Roboto', sans-serif;
}

.screen {
	background: rgba(0,0,0,.6);
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	top: 0;
	z-index: 0;
}

.content {
	left: 50%;
	position: relative;
	top: 50%;
	text-align: center;
	transform: translate(-50%,-50%);
	z-index: 1;
	
	h2 {
		font-size: 36px;
		letter-spacing: 2px;
		line-height: 42px;

		@include breakpoint($tablet) {
			font-size: 30px;
			letter-spacing: 1.67px;
			line-height: 35px;
		}

		@include breakpoint($mobile) {
			font-size: 24px;
			letter-spacing: 1.33px;
			line-height: 28px;
		}
	}
	
	.logo {
		//background: url(/images/logo.svg) no-repeat;
		display: block;
		height: auto;
		margin: 31px auto 62px;
		max-width: auto;
		width: 668px;

		@include breakpoint($tablet) {
			margin: 16px auto 30px;
			max-width: 61%;
		}

		@include breakpoint($mobile) {
			margin: 10px auto 30px;
			max-width: 75%;
		}
	}

	.crafted-coffee {

	}

	h1 {
		font-size: 36px;
		letter-spacing: 2px;
		line-height: 42px;

		@include breakpoint($tablet) {
			font-size: 30px;
			letter-spacing: 1.67px;
			line-height: 35px;
			padding: 0;
		}

		@include breakpoint($mobile) {
			font-size: 24px;
			letter-spacing: 1.33px;
			line-height: 28px;
			padding: 0 15%;
		}

		@include breakpoint($mobile-small) {
			padding: 0 10%;
		}

		br {
			@include breakpoint($mobile) {
				display: none;
			}
		}
	}
}

.nzcra-logo {
	bottom: 25px;
	display: block;
	height: auto;
	left: 25px;
	position: absolute;
	width: 200px;

	@include breakpoint($tablet) {
		width: 150px;
	}

	@include breakpoint($mobile) {
		width: 100px;
	}
}

.contact-button {
	border: 3px solid #fff;
	color: #fff;
	cursor: pointer;
	font-size: 20px;
	font-weight: 700;
	line-height: 52px;
	height: 58px;
	overflow: hidden;
	padding: 0 ;
	position: absolute;
	right: 50px;
	text-align: center;
	text-transform: uppercase;
	top: 50px;
	transition: all .2s ease-in-out;
	width: 193px;
	z-index: 3;

	@include breakpoint($mobile) {
		font-size: 15px;
		left: 50%;
		height: 44px;
		line-height: 38px;
		right: none;
		top: 25px;
		transform: translateX(-50%);
		width: 145px;
	}

	&:hover {
		background: #000;
	}

	span {
		position: relative;
		top: 0;
		transition: top .3s cubic-bezier(.75,0,.25,1);;

		&.active {
			top: -52px;

			@include breakpoint($mobile) {
				top: -38px;
			}
		}
	}
}

.contact {
	background: #000;
	height: 0;
	right: 0;
	position: absolute;
	top: 0;
	text-align: center;
	transition: height .3s cubic-bezier(.75,0,.25,1), z-index 0s .3s;

	//transform 1s cubic-bezier(.75,0,.25,1),opacity 1s,visibility 0s 1s,-webkit-transform 1s cubic-bezier(.75,0,.25,1)

	width: 50%;
	z-index: 0;

	@include breakpoint($tablet-portrait) {
		width: 80%;
	}

	@include breakpoint($mobile) {
		width: 100%;
	}

	&.active {
		height: 100%;
		transition: height .3s cubic-bezier(.75,0,.25,1), z-index 0s 0s;
		z-index: 2;
	}

	.contact-copy {
		font-size: 36px;
		line-height: 42px;
		margin-top: 26.75vh;
		opacity: 0;
		transition: opacity .20s cubic-bezier(.75,0,.25,1);

		&.active {
			opacity: 1;
			transition: opacity .20s cubic-bezier(.75,0,.25,1) .10s;
		}

		@include breakpoint($tablet) {
			font-size: 30px;
			line-height: 35px;
			margin-top: 26.75vh;
		}

		@include breakpoint($mobile) {
			font-size: 25px;
			line-height: 28px;
			margin-top: 31.63vh;
		}

		a {
			color: #fff;
			font-weight: 700;
			text-decoration: none;
			transition: all .20s cubic-bezier(.75,0,.25,1);

			&:hover {
				opacity: .75;
			}
		}
	}

	.contact-address {
		font-size: 24px;
		line-height: 28px;
		margin-top: 19vh;
		opacity: 0;
		transition: opacity .20s cubic-bezier(.75,0,.25,1);

		span {
			font-weight: 700;
		}

		&.active {
			opacity: 1;
			transition: opacity .20s cubic-bezier(.75,0,.25,1) .10s;
		}

		@include breakpoint($tablet) {
			font-size: 20px;
			line-height: 24px;
			margin-top: 19vh;
		}

		@include breakpoint($mobile) {
			margin-top: 15.44vh;
		}

		a {
			color: #fff;
			text-decoration: none;
			transition: all .20s cubic-bezier(.75,0,.25,1);

			&:hover {
				opacity: .75;
			}
		}
	}
}